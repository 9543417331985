import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'
import useBodyClass from 'hooks/useBodyClass';
import { AuthRoute, RouteInterceptor } from "configs/RoutingConfig";

export const Views = (props) => {
  const { locale, location, direction, userAuth } = props;
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);
  console.log("userAuth", userAuth);
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path="/">
            <Redirect to={AUTH_PREFIX_PATH} />
          </Route>
          <AuthRoute path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </AuthRoute>

          <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={userAuth}>
            <AppLayout direction={direction} location={location} />
          </RouteInterceptor>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme;
  const { userAuth } = auth;
  return { locale, userAuth, direction }
};

export default withRouter(connect(mapStateToProps)(Views));