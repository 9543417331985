import { APP_PREFIX_PATH } from 'configs/AppConfig'
import {
  DashboardOutlined,
  FundOutlined,
  AuditOutlined,
  UserAddOutlined,
  FlagOutlined,
  UserSwitchOutlined,
  FieldBinaryOutlined,
  OrderedListOutlined,
  UnorderedListOutlined,
  DeliveredProcedureOutlined
} from '@ant-design/icons';

const dashBoardNavTree = [{
  key: 'dashboards',
  path: `${APP_PREFIX_PATH}/dashboards`,
  title: 'sidenav.dashboard',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'dashboards-home',
      path: `${APP_PREFIX_PATH}/dashboards/dashoardHome`,
      title: 'sidenav.dashboard.home',
      icon: FundOutlined,
      breadcrumb: false,
      submenu: [],
    },
    {
      key: 'dashboards-banner',
      path: `${APP_PREFIX_PATH}/dashboards/banner`,
      title: 'sidenav.dashboard.banner',
      icon: FlagOutlined,
      breadcrumb: false,
      submenu: [],
    },
    {
      key: 'dashboards-user',
      path: `${APP_PREFIX_PATH}/dashboards/users`,
      title: 'sidenav.dashboard.users',
      icon: UserSwitchOutlined,
      breadcrumb: false,
      submenu: [],
    },
    {
      key: 'dashboards-categories',
      path: `${APP_PREFIX_PATH}/dashboards/categories`,
      title: 'sidenav.dashboard.categories',
      icon: AuditOutlined,
      breadcrumb: false,
      submenu: [],
    },
    {
      key: 'dashboards-subcategories',
      path: `${APP_PREFIX_PATH}/dashboards/subCategories`,
      title: 'sidenav.dashboard.subcategories',
      icon: AuditOutlined,
      breadcrumb: false,
      submenu: [],
    }


  ]
}]

const bidProdNavTree = [{
  key: 'bid',
  path: `${APP_PREFIX_PATH}/bid`,
  title: 'sidenav.bid',
  icon: FieldBinaryOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'bid-Create-produts',
      path: `${APP_PREFIX_PATH}/bidope/createBidProducts`,
      title: 'sidenav.bid.CreatebidProduct',
      icon: FieldBinaryOutlined,
      breadcrumb: false,
      submenu: [],
    },
    {
      key: 'bid-produts',
      path: `${APP_PREFIX_PATH}/bidope/bidProducts`,
      title: 'sidenav.bid.bidProduct',
      icon: FieldBinaryOutlined,
      breadcrumb: false,
      submenu: [],
    }
  ]
}]

const vendorProdNavTree = [{
  key: 'vendor',
  path: `${APP_PREFIX_PATH}/vendor`,
  title: 'sidenav.vendor',
  icon: FieldBinaryOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'vendor-Create-produts',
      path: `${APP_PREFIX_PATH}/vendor/vendor_List`,
      title: 'sidenav.vendor.vendorCreate',
      icon: UserAddOutlined,
      breadcrumb: false,
      submenu: [],
    },
    {
      key: 'vendor-Create-produts-List',
      path: `${APP_PREFIX_PATH}/vendor/vendor_Product_list`,
      title: 'sidenav.vendor.vendorList',
      icon: UnorderedListOutlined,
      breadcrumb: false,
      submenu: [],
    },
    {
      key: 'vendor-pending-produts-List',
      path: `${APP_PREFIX_PATH}/vendor/pending_Vendor_Product`,
      title: 'sidenav.vendor.pending',
      icon: OrderedListOutlined,
      breadcrumb: false,
      submenu: [],
    },
    {
      key: 'vendor-pending-Delivery-Hub',
      path: `${APP_PREFIX_PATH}/vendor/delivery_Hub`,
      title: 'sidenav.vendor.deliveryHub',
      icon: DeliveredProcedureOutlined,
      breadcrumb: false,
      submenu: [],
    }
  ]
}]

const navigationConfig = [
  ...dashBoardNavTree,
  ...bidProdNavTree,
  ...vendorProdNavTree
]

export default navigationConfig;
